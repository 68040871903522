export const Spinner = () => {
  return (
    // bouncer loader
    <div className='lds-ring'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
