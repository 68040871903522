import { Heading, Container } from '../components';

export const Profile = () => {
  return (
    <Container>
      <Heading>Profile</Heading>
      <main>
        <p>data...</p>
      </main>
    </Container>
  );
};
