// Publications
export const GET_ALL_PUBLICATIONS = 'GET_ALL_PUBLICATIONS';
export const GET_FILTERED_PUBLICATIONS = 'GET_FILTERED_PUBLICATIONS';
export const PUBLICATIONS_ERROR = 'PUBLICATIONS_ERROR';

// Single Publication
export const GET_SINGLE_PUBLICATION = 'GET_SINGLE_PUBLICATION';
export const SINGLE_PUBLICATION_ERROR = 'SINGLE_PUBLICATION_ERROR';
export const RESET_SINGLE_PUBLICATION_LOADING =
  'RESET_SINGLE_PUBLICATION_LOADING';
export const UPDATE_SINGLE_PUBLICATION = 'UPDATE_SINGLE_PUBLICATION';
export const CREATE_SINGLE_PUBLICATION = 'CREATE_SINGLE_PUBLICATION';
export const DELETE_SINGLE_PUBLICATION = 'DELETE_SINGLE_PUBLICATION';

// Authors
export const GET_ALL_AUTHORS = 'GET_ALL_AUTHORS';
export const AUTHORS_ERROR = 'AUTHORS_ERROR';
export const GET_SINGLE_AUTHOR = 'GET_SINGLE_AUTHOR';
export const SINGLE_AUTHOR_ERROR = 'SINGLE_AUTHOR_ERROR';
export const RESET_SINGLE_AUTHOR_LOADING = 'RESET_SINGLE_AUTHOR_LOADING';

// Author Admin
export const CREATE_SINGLE_AUTHOR = 'CREATE_SINGLE_AUTHOR';
export const UPDATE_SINGLE_AUTHOR = 'UPDATE_SINGLE_AUTHOR';
export const DELETE_SINGLE_AUTHOR = 'DELETE_SINGLE_AUTHOR';

export const GET_ALL_AUTHOR_PUBLICATIONS = 'GET_ALL_AUTHOR_PUBLICATIONS';
export const AUTHOR_PUBLICATIONS_ERROR = 'AUTHOR_PUBLICATIONS_ERROR';
export const RESET_SINGLE_AUTHOR_PUBLICATIONS_LOADING =
  'RESET_SINGLE_AUTHOR_PUBLICATIONS_LOADING';

// Interactions
export const SET_QUERY = 'SET_QUERY';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_SORT = 'SET_SORT';
export const TOGGLE_FILTERS_TOUCHED = 'TOGGLE_FILTERS_TOUCHED';
export const SEARCH_RESULTS = 'SEARCH_RESULTS';

// Authentication
export const UPDATE_AUTH = 'UPDATE_AUTH';

// Users
export const GET_USER_BY_UID = 'GET_USER_BY_UID';
export const CLEAR_USER = 'CLEAR_USER';
export const USER_ERROR = 'USER_ERROR';
